/**
 * Renders a banner section with a slanted background image
 * has option of heading, text, videobutton
 */

import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { Heading1 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';
import sanityImage from '../../../utils/sanityImage';
import VideoButton from '../../atoms/Buttons/VideoButton/VideoButton';
import TextBlock from '../../molecules/TextBlock/TextBlock';
import PageWrapper from '../PageWrapper/PageWrapper';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  headingColors,
  lineHeights,
  pageDimensions,
  unitConverter as uc,
} from '../../../styles/base';

const HeroBanner = ({
  className,
  heroImage,
  textBlock: {
    align,
    description,
    descriptionColor = colors.white,
    headingText,
    headingTextColor = colors.white,
    ...textBlockProps
  },
  videoButton,
}) => {
  const centeredText = align === 'center';
  const heroBackgroundImage = heroImage.asset
    ? sanityImage(heroImage)
        .auto('format')
        .width(pageDimensions.rawLargeDesktopWidth)
        .url()
    : heroImage;

  const sectionCss = css`
    position: relative;
    overflow: hidden;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url(${heroBackgroundImage});
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
      content: '';
    }
  `;

  // create a white triangle at the bottom of the banner to create slant
  // 1920 is the max page width, so let's calculate the biggest it needs to be
  // use tan(7.2) = opposite side / 1920 => 243
  const slantCss = css`
    position: absolute;
    right: 0;
    bottom: ${uc('-243px')};
    left: 0;
    z-index: 1;
    height: ${uc('243px')};
    background: white;
    transform: skewY(-7.2deg);
    transform-origin: left center;
  `;

  const containerCss = css`
    position: relative;
    z-index: 1;
    display: flex;
    ${centeredText && 'justify-content: center;'}
    align-items: center;
    height: 100%;
  `;

  const textBlockCss = css`
    display: flex;
    flex-direction: column;
    padding: ${uc('240px 25px 120px')};
    line-height: ${lineHeights.copy};
    ${centeredText && 'justify-content: center;'}
    text-align: ${align};

    @media (max-width: ${pageDimensions.desktopWidth}) {
      div.textBlockCss {
        padding: 0;
        background-color: transparent;
      }
    }

    @media (${breakpoints.tablet}) {
      padding: ${uc('220px 25px 95px')};
    }

    @media (${breakpoints.mobile}) {
      padding: ${uc('170px 25px 95px')};
    }
  `;

  const textCss = css`
    max-width: 70%;
    margin: 0 auto ${uc('50px')} auto;
    font-size: ${uc('35px')};

    @media (max-width: ${pageDimensions.desktopWidth}) {
      max-width: 65%;
      font-size: ${fontSizes.thirty};
    }

    @media (${breakpoints.tablet}) {
      font-size: ${fontSizes.twenty};
    }

    @media (${breakpoints.mobile}) {
      max-width: 85%;
      font-size: ${fontSizes.fifteen};
    }
  `;
  const heading1Css = css`
    max-width: ${uc('850px')};
    margin-bottom: ${uc('50px')};
    ${!centeredText && 'text-align: left;'}
    ${centeredText && 'align-self: center;'}
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes.fifty};
    line-height: auto;

    span {
      display: block;
      margin-top: ${uc('20px')};
      font-weight: ${fontWeights.thin};
      font-family: ${fontFamilies.roboto};
    }

    @media (${breakpoints.desktop}) {
      margin-bottom: ${uc('20px')};
      font-size: ${fontSizes.forty};
    }

    @media (${breakpoints.tablet}) {
      max-width: 65%;
      margin-bottom: ${uc('25px')};
      font-size: ${fontSizes.twentyEight};
    }

    @media (${breakpoints.mobile}) {
      max-width: 80%;
      font-size: ${fontSizes.eighteen};
    }

    @media (${breakpoints.mdpiLaptop}) {
      max-width: ${uc('600px')};
    }
  `;

  return (
    <section css={sectionCss} className={`hero-banner ${className}`}>
      <div className="slant" css={slantCss} />
      <PageWrapper className="page-wrapper" css={containerCss}>
        <div className="text-block" css={textBlockCss}>
          <Heading1 color={headingColors[headingTextColor]} css={heading1Css}>
            {headingText}
          </Heading1>
          <Text color={headingColors[descriptionColor]} css={textCss}>
            {description}
          </Text>
          <TextBlock align={align} {...textBlockProps} />
          {videoButton && (
            <VideoButton
              color={videoButton.color}
              className="video-button"
              {...videoButton}
            >
              {videoButton.buttonText}
            </VideoButton>
          )}
        </div>
      </PageWrapper>
    </section>
  );
};

HeroBanner.propTypes = {
  className: PropTypes.string,
  heroImage: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  textBlock: PropTypes.instanceOf(Object),
  videoButton: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
};

HeroBanner.defaultProps = {
  className: '',
  heroImage: '',
  textBlock: {},
  videoButton: false,
};

export default HeroBanner;
